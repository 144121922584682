.heatmap_tooltip {
  background-color: #fff;
  border-radius: 10%;
  border-width: 1px;
  border-color: #ddd;
  border-style: solid;
  padding: 5px;
}

.discrete_legend_entry div {
  height: 25px;
  width: 80px;
  border-radius: 10%;
  margin-bottom: 5px;
}

.discrete_legend_entry span {
  margin-left: 0px !important;
}

.gradient_color_block {
  width: 250px;
  height: 25px;
  border-radius: 5px;
  background: linear-gradient(
    0.25turn,
    #0766be 5%,
    #a4d3ff 49.5%,
    #ccc 50%,
    #ffd6c3 50.5%,
    #dc6a37 97.45%
  );
}
