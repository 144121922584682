.headerBox {
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
}

.formContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  height: auto;
  gap: 32px;
}

.formInnerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.formColumn {
  flex: 1;
  min-width: 250px;
}

.formColumnContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.formColumnItem {
}
